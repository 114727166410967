// This project is setup using redux-toolkit which includes immer by default.
// Hence state is mutable.

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { addAlert } from '../../components/clientComponents/alert/alertSlice';

import { AppThunk, RootState } from '../../store';
import loginState from './initialState';
import LoginState from './interface';

const initialState: LoginState = {
  ...loginState,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
  },
});

export const { setLoading, setEmail, setPassword } = loginSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const signOut =
  (): AppThunk => async (dispatch, getState, getFirebase) => {
    const firebaseInstance = getFirebase();
    try {
      await firebaseInstance.auth().signOut();
      batch(() => {
        dispatch(
          addAlert({
            message: 'Logged Out Successfully',
            messageType: 'SUCCESS',
          })
        );
        localStorage.removeItem('authToken');
      });
    } catch (err) {
      dispatch(addAlert({ message: 'Try Again', messageType: 'FAILURE' }));
    }
  };

export const signIn =
  (email: string, password: string): AppThunk =>
  async (dispatch, getState, getFirebase) => {
    dispatch(setLoading(true));
    const firebaseInstance = getFirebase();
    try {
      await firebaseInstance
        .auth()
        .signInWithEmailAndPassword(email.trim(), password.trim());
      batch(() => {
        dispatch(setLoading(false));
        dispatch(
          addAlert({
            message: 'Logged In Successfully',
            messageType: 'SUCCESS',
          })
        );
        dispatch(setEmail(''));
        dispatch(setPassword(''));
      });
    } catch (err) {
      batch(() => {
        dispatch(setLoading(false));
        dispatch(
          addAlert({
            message: 'Invalid/Wrong Credentials',
            messageType: 'FAILURE',
          })
        );
      });
    }
  };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectLoading = (state: RootState): boolean => state.login.loading;
export const selectEmail = (state: RootState): string => state.login.email;
export const selectPassword = (state: RootState): string =>
  state.login.password;

export default loginSlice.reducer;
