import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';

import style from './app.module.css';
import { signOut } from './app/containers/loginPage/loginSlice';
import LoginPage from './app/containers/loginPage';
import BigLoader from './app/components/clientComponents/bigLoader';
import SideBar from './app/components/clientComponents/sideBar';
import ReviewPage from './app/containers/reviewPage';
import HomePage from './app/components/homePage';
import ClientPagesRoute from './app/containers/clientReviewRoutePage';
import Alert from './app/components/clientComponents/alert';

const App = () => {
  const dispatch = useDispatch();
  const firebaseAuth = useSelector((state: any) => state.firebase.auth);
  const firebaseProfile = useSelector((state: any) => state.firebase.profile);
  const isInternal =
    firebaseProfile.userAccessLevel &&
    firebaseProfile.userAccessLevel[8] === '1';
  const signOutHandler = () => {
    dispatch(signOut());
  };

  const authenticatedRoutes = (
    <div className={isInternal ? style.container_internal : style.container}>
      {isInternal ? null : (
        <SideBar
          userAccessLevel={firebaseProfile?.userAccessLevel}
          signOut={signOutHandler}
        />
      )}
      <div className={isInternal ? style.container_internal : style.content}>
        {isInternal ? (
          <Switch>
            <Route exact path="/review/:requestId" component={ReviewPage} />
            <Route exact path="*" component={HomePage} />
          </Switch>
        ) : (
          <ClientPagesRoute />
        )}
      </div>
      <Alert />
    </div>
  );
  const unAuthenticatedRoutes = (
    <Switch>
      <Route exact path="/login" component={() => <LoginPage />} />
      <Redirect to="/login" />
    </Switch>
  );
  return (
    <div className={style._}>
      <Router>
        {!isLoaded(firebaseAuth) && (
          <div className={`${style._} ${style.center} ${style.display_flex}`}>
            <BigLoader />
          </div>
        )}
        {isLoaded(firebaseAuth) && isEmpty(firebaseAuth) && (
          <div className={`${style.open_container} ${style.display_flex}`}>
            {unAuthenticatedRoutes}
          </div>
        )}
        {isLoaded(firebaseAuth) &&
          !isEmpty(firebaseAuth) &&
          !isLoaded(firebaseProfile) && (
            <div className={`${style._} ${style.center} ${style.display_flex}`}>
              <BigLoader />
            </div>
          )}
        {isLoaded(firebaseAuth) &&
          !isEmpty(firebaseAuth) &&
          isLoaded(firebaseProfile) &&
          authenticatedRoutes}
      </Router>
    </div>
  );
};

export default App;
