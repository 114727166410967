/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import axios from 'axios';
import {
  RiCheckboxBlankCircleLine,
  RiCheckboxBlankCircleFill,
} from 'react-icons/ri';
import style from './verdict.module.css';
import Label from '../label';
import CircularLoader from '../circularLoader';
import { ClientReviewHit } from '../../../containers/clientReviewPage/interface';
import { addAlert } from '../alert/alertSlice';

type Props = {
  suspicious: boolean;
  hit: ClientReviewHit;
  requestId: string;
};

const Verdict = ({ requestId, suspicious, hit }: Props) => {
  const [loader, setLoader] = useState(false);
  const [accept, setAccept] = useState(false);
  const [reject, setReject] = useState(false);

  const handleAccept = () => {
    setReject(false);
    setAccept(!accept);
  };
  const handleReject = () => {
    setAccept(false);
    setReject(!reject);
  };
  const [suggestion, setSuggestion] = useState('');

  const handleSuggestionChange = (e: any) => setSuggestion(e.target.value);
  const verdictResult = suspicious
    ? 'Verdict: Suspicious match'
    : 'Verdict: No suspicious match';

  const submitSuggestion = async (e: any) => {
    e.preventDefault();

    setLoader(true);
    const clientDecision = accept
      ? 'Accept'
      : reject
      ? 'Reject'
      : 'No Decision';

    const webhookUrl =
      'https://hooks.slack.com/services/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';

    const data = {
      text: `
*Suggestion for RequestId: ${requestId}*
AI Decision:
	- selfie: ${hit.aiDecision.selfie}
	- idFace: ${hit.aiDecision.idFace}
	- idNumber: ${hit.aiDecision.idNumber}
Match Score:
	- selfie: ${hit.matchScore.selfie}
	- idFaceCrop: ${hit.matchScore.idFaceCrop}
*Orion Reason*: ${hit.reason}
*Client Decision*: ${clientDecision}
*Client Feedback*: ${suggestion}
			`,
    };

    const res = await axios.post(webhookUrl, JSON.stringify(data), {
      withCredentials: false,
      transformRequest: [
        (reqData, headers) => {
          // eslint-disable-next-line no-param-reassign
          delete headers.post['Content-Type'];
          return reqData;
        },
      ],
    });
    setLoader(false);
    setAccept(false);
    setReject(false);
    setSuggestion('');
    if (res.status === 200) {
      addAlert({
        message: 'Suggestion Sent',
        messageType: 'SUCCESS',
      });
    } else {
      addAlert({
        message: 'There was an error. Please try again later.',
        messageType: 'FAILURE',
      });
    }
  };

  return (
    <div className={style.container_sm}>
      <div className={style.container_wrapper}>
        <div className={style.container_heading}>{verdictResult}</div>
        <div className={style.container_content}>
          <div className={style.verdict_wrapper}>
            <strong>
              <Label
                itemKey="Reason"
                value={hit.reason || ''}
                fontWeight={800}
                padding="0.7rem 1rem"
              />
            </strong>
          </div>
          <div className={style.options}>
            <div className={style.select} onClick={handleAccept}>
              <div className={style.select_wrapper}>
                {accept ? (
                  <RiCheckboxBlankCircleFill
                    style={{
                      cursor: 'pointer',
                      fontSize: '1.5rem',
                      color: '#00A510',
                    }}
                  />
                ) : (
                  <RiCheckboxBlankCircleLine
                    style={{
                      cursor: 'pointer',
                      fontSize: '1.5rem',
                      color: '#00A510',
                    }}
                  />
                )}
              </div>
              <span>Agree</span>
            </div>
            <div className={style.select} onClick={handleReject}>
              <div className={style.select_wrapper}>
                {reject ? (
                  <RiCheckboxBlankCircleFill
                    style={{
                      cursor: 'pointer',
                      fontSize: '1.5rem',
                      color: '#FF0000',
                    }}
                  />
                ) : (
                  <RiCheckboxBlankCircleLine
                    style={{
                      cursor: 'pointer',
                      fontSize: '1.5rem',
                      color: '#FF0000',
                    }}
                  />
                )}
              </div>
              <span>Disagree</span>
            </div>
          </div>
          <div className={style.suggestion}>
            <span>Remark</span>
            <textarea value={suggestion} onChange={handleSuggestionChange} />
          </div>
          <div onClick={submitSuggestion} className={style.btn}>
            {loader ? <CircularLoader /> : <span>Submit</span>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Verdict;
