import React, { useEffect, useState } from 'react';
import { BsX } from 'react-icons/bs';
import { BiCheck } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';

import style from './alert.module.css';
import {
  removeAlert,
  selectAlertMessage,
  selectAlertMessageType,
} from './alertSlice';

const MESSAGE_TYPE = {
  SUCCESS: {
    backgroundColor: '#EAFFF8',
    border: 'solid 1px #257861',
    color: '#257861',
  },
  FAILURE: {
    backgroundColor: '#FFE1DD',
    border: 'solid 1px #F75D47',
    color: '#F75D47	',
  },
};

const Alert = () => {
  const [hideError, setHideError] = useState('');
  const dispatch = useDispatch();
  const alertMessage = useSelector(selectAlertMessage);
  const alertMessageType = useSelector(selectAlertMessageType);

  useEffect(() => {
    if (alertMessage !== '') {
      setHideError(style.unhide);
      setTimeout(() => {
        setHideError(style.hide);
        setTimeout(() => {
          dispatch(removeAlert());
        }, 600);
      }, 2000);
    }
  }, [dispatch, alertMessage]);

  if (alertMessage !== '') {
    const alertMessageTypeVar =
      alertMessageType === 'SUCCESS' ? 'SUCCESS' : 'FAILURE';
    return (
      <div
        className={`${style.alert} ${hideError}`}
        style={MESSAGE_TYPE[alertMessageTypeVar]}
      >
        {alertMessageType === 'SUCCESS' ? (
          <div className={`${style.icon_wrapper} ${style.success}`}>
            <BiCheck />
          </div>
        ) : (
          <div className={`${style.icon_wrapper} ${style.failure}`}>
            <BsX />
          </div>
        )}
        <span>{alertMessage}</span>
      </div>
    );
  }
  return <div />;
};

export default Alert;
