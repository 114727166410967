// This project is setup using redux-toolkit which includes immer by default.
// Hence state is mutable.

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { nullReviewPageData, nullReviewPageHits } from './initialState';
import {
  DedupeReviewPageState,
  ReviewPageData,
  ReviewPageHits,
} from './interface';

const initialState: DedupeReviewPageState = {
  reviewPageData: nullReviewPageData,
  reviewPageHits: nullReviewPageHits,
};

export const dedupeReviewSlice = createSlice({
  name: 'dedupereview',
  initialState,
  reducers: {
    setDedupeReviewPageHits: (state, action: PayloadAction<ReviewPageHits>) => {
      state.reviewPageHits = action.payload;
    },
    setDedupeReviewPageData: (state, action: PayloadAction<ReviewPageData>) => {
      state.reviewPageData = action.payload;
    },
  },
});

export const { setDedupeReviewPageHits, setDedupeReviewPageData } =
  dedupeReviewSlice.actions;

export const selectDedupeReviewPageData = (state: RootState): ReviewPageData =>
  state.dedupereview.reviewPageData;
export const selectDedupeReviewPageHits = (state: RootState): ReviewPageHits =>
  state.dedupereview.reviewPageHits;

export default dedupeReviewSlice.reducer;
