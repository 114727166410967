import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectClientQueryLog,
  selectNavigationData,
  selectOnBlocklistTab,
  selectOnMatchTab,
  selectOnMismatchTab,
  setOnBlocklistTab,
  setOnMatchTab,
  setOnMismatchTab,
} from '../clientReviewPage/clientReviewPageSlice';
import { ClientQueryLog } from '../clientReviewPage/interface';
import {
  selectDedupeReviewPageData,
  selectDedupeReviewPageHits,
  setDedupeReviewPageData,
  setDedupeReviewPageHits,
} from './dedupeReviewPageSlice';
import {
  nullHit,
  nullReviewPageData,
  nullReviewPageHits,
} from './initialState';
import { ReviewPageHits } from './interface';
import style from './dedupeReviewPage.module.css';
import TagBar from '../../components/clientComponents/tagBar';
import ComparisonContainer from '../../components/clientComponents/comparisonContainer';
import { getDedupeReviewPageData } from './utils';

type Props = {
  shiftTabsAndIndex: (e: any) => void;
  toggleCategory: (togglevalue: string) => void;
  changeCurrIndex: (index: number) => void;
  requestId: string;
};

const DedupeReviewPage = ({
  shiftTabsAndIndex,
  toggleCategory,
  changeCurrIndex,
  requestId,
}: Props) => {
  const dispatch = useDispatch();
  const queryLog = useSelector(selectClientQueryLog) as ClientQueryLog;
  const navigation = useSelector(selectNavigationData);
  const onMismatchTab = useSelector(selectOnMismatchTab);
  const onMatchTab = useSelector(selectOnMatchTab);
  const onBlocklistTab = useSelector(selectOnBlocklistTab);

  useEffect(() => {
    const totalMismatches = queryLog.count?.total?.mismatches;
    const totalBlocklist = queryLog.count?.total?.blocklist;

    const hitData: ReviewPageHits = {
      matches: [],
      mismatches: [],
      blocklist: [],
    };

    hitData.matches =
      queryLog.hits?.matches?.length > 0
        ? queryLog.hits.matches
        : nullReviewPageHits.matches;

    hitData.mismatches =
      queryLog.hits?.mismatches?.length > 0
        ? queryLog.hits.mismatches
        : nullReviewPageHits.mismatches;

    hitData.blocklist =
      queryLog.hits?.blocklist && queryLog.hits?.blocklist?.length > 0
        ? queryLog.hits.blocklist
        : nullReviewPageHits.blocklist;

    dispatch(setDedupeReviewPageHits(hitData));

    dispatch(setOnMismatchTab(false));
    dispatch(setOnBlocklistTab(false));
    dispatch(setOnMatchTab(false));

    if (totalMismatches > 0) {
      dispatch(setOnMismatchTab(true));
    } else if (totalBlocklist && totalBlocklist > 0) {
      dispatch(setOnBlocklistTab(true));
    } else {
      dispatch(setOnMatchTab(true));
    }

    const dedupeReviewPageData = getDedupeReviewPageData(queryLog);
    dispatch(setDedupeReviewPageData(dedupeReviewPageData));
  }, [dispatch, queryLog]);

  const dedupeReviewPageData = useSelector(selectDedupeReviewPageData);
  const dedupeReviewPageHits = useSelector(selectDedupeReviewPageHits);

  let currentHit;
  if (onMismatchTab) {
    currentHit =
      dedupeReviewPageHits.mismatches[
        (navigation.mismatches.currIndex
          ? navigation.mismatches.currIndex
          : 1) - 1
      ];
  } else if (onMatchTab) {
    currentHit =
      dedupeReviewPageHits.matches[
        (navigation.matches.currIndex ? navigation.matches.currIndex : 1) - 1
      ];
  } else if (onBlocklistTab) {
    currentHit =
      dedupeReviewPageHits.blocklist[
        (navigation.blocklist.currIndex ? navigation.blocklist.currIndex : 1) -
          1
      ];
  }

  const dedupeReviewPage = (
    <div onKeyDown={shiftTabsAndIndex} className={style.container}>
      {dedupeReviewPageData !== nullReviewPageData && (
        <TagBar
          data={dedupeReviewPageData.tagBar}
          navigation={navigation}
          changeCurrIndex={changeCurrIndex}
          onMismatchTab={onMismatchTab}
          onMatchTab={onMatchTab}
          onBlocklistTab={onBlocklistTab}
          toggleCategory={toggleCategory}
          selfieOnlyQC={dedupeReviewPageData.selfieOnlyQC}
          customClientChangeCathay={
            dedupeReviewPageData.customClientChangeCathay
          }
          displayHitDob={dedupeReviewPageData.displayHitDob}
          timestamp={dedupeReviewPageData.timestamp}
          requestId={queryLog.requestId}
          hit={currentHit || nullHit}
        />
      )}
      {dedupeReviewPageData !== nullReviewPageData && (
        <ComparisonContainer
          requestId={requestId}
          data={dedupeReviewPageData.results}
          suspicious={dedupeReviewPageData.suspicious}
          hit={currentHit || nullHit}
          selfieOnlyQC={dedupeReviewPageData.selfieOnlyQC}
          selfieOnlyQCWithIdDetails={
            dedupeReviewPageData.selfieOnlyQCWithIdDetails
          }
          faceSearchQC={dedupeReviewPageData.faceSearchQC}
          customClientChangeCathay={
            dedupeReviewPageData.customClientChangeCathay
          }
        />
      )}
    </div>
  );
  return dedupeReviewPage;
};

export default DedupeReviewPage;
