import React from 'react';

import style from './circularLoader.module.css';

const CircularLoader = () => {
  return (
    <div className={style._}>
      <span />
    </div>
  );
};
export default CircularLoader;
