import React from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';

import style from './tag.module.css';

type Props = {
  itemKey: string;
  value: any;
  color?: string;
  borderColor?: string;
  backgroundColor?: string;
  valueColor?: string;
};

const Tag = ({
  itemKey,
  value,
  color,
  borderColor,
  backgroundColor,
  valueColor,
}: Props) => {
  let resultComponent;
  if (value === 'tick') {
    resultComponent = <FaRegCheckCircle />;
  } else if (value === 'cross') {
    resultComponent = <ImCancelCircle />;
  } else if (value !== '') {
    resultComponent = value;
  } else {
    resultComponent = '';
  }

  return (
    <div
      className={style.tag}
      style={{
        borderColor,
        backgroundColor,
      }}
    >
      <span
        className={style.tag_key}
        style={{
          color,
        }}
      >
        {itemKey}
      </span>
      <span className={style.tag_value} style={{ color: valueColor }}>
        {resultComponent}
        {}
      </span>
    </div>
  );
};

export default Tag;
