import React from 'react';
import { isNil } from 'lodash';

type Props = {
  type: string;
  value: any;
  className: string;
};

const RenderIfAvailable = ({ type, value, className }: Props) => {
  const render = isNil(value) ? null : (
    <span>
      {type}:{' '}
      <span className={className}>
        {typeof value === 'string' ? value : JSON.stringify(value)}
      </span>
    </span>
  );
  return render;
};

export default RenderIfAvailable;
