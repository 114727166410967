
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

export interface AlertState {
  message: string;
  messageType: string;
}

const initialState: AlertState = {
  message: '',
  messageType: '',
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<AlertState>) => {
      state.message = action.payload.message;
      state.messageType = action.payload.messageType;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeAlert: (state, action: PayloadAction<void>) => {
      state.message = '';
      state.messageType = '';
    },
  },
});

export const { addAlert, removeAlert } = alertSlice.actions;

export const selectAlertMessage = (state: RootState): string =>
  state.alert.message;
export const selectAlertMessageType = (state: RootState): string =>
  state.alert.messageType;

export default alertSlice.reducer;
