import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { addAlert } from '../../components/clientComponents/alert/alertSlice';
import { AppThunk, RootState } from '../../store';
import {
  setDedupeReviewPageData,
  setDedupeReviewPageHits,
} from '../dedupeReviewPage/dedupeReviewPageSlice';
import {
  nullReviewPageData,
  nullReviewPageHits,
} from '../dedupeReviewPage/initialState';
import { setFaceAuthReviewPageData } from '../faceAuthReviewPage/faceAuthReviewPageSlice';
import nullFaceAuthData from '../faceAuthReviewPage/initialState';
import { initialNavigation, nullQueryLog } from './initialState';
import ClientReviewState, {
  ClientFaceAuthLog,
  ClientQueryLog,
  Navigation,
} from './interface';

const initialState: ClientReviewState = {
  queryLog: nullQueryLog,
  loader: false,
  isLogNotFound: false,
  onMismatchTab: false,
  onMatchTab: false,
  onBlocklistTab: false,
  navigation: initialNavigation,
};

export const clientReviewSlice = createSlice({
  name: 'clientreview',
  initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    setLogNotFound: (state, action: PayloadAction<boolean>) => {
      state.isLogNotFound = action.payload;
    },
    setClientQueryLog: (
      state,
      action: PayloadAction<ClientQueryLog | ClientFaceAuthLog>
    ) => {
      state.queryLog = action.payload;
    },
    setNavigationData: (state, action: PayloadAction<Navigation>) => {
      state.navigation = action.payload;
    },
    setMismatchIndex: (state, action: PayloadAction<number>) => {
      state.navigation.mismatches.currIndex = action.payload;
    },
    setMatchIndex: (state, action: PayloadAction<number>) => {
      state.navigation.matches.currIndex = action.payload;
    },
    setBlocklistIndex: (state, action: PayloadAction<number>) => {
      state.navigation.blocklist.currIndex = action.payload;
    },
    setOnMismatchTab: (state, action: PayloadAction<boolean>) => {
      state.onMismatchTab = action.payload;
    },
    setOnMatchTab: (state, action: PayloadAction<boolean>) => {
      state.onMatchTab = action.payload;
    },
    setOnBlocklistTab: (state, action: PayloadAction<boolean>) => {
      state.onBlocklistTab = action.payload;
    },
  },
});

export const {
  setLoader,
  setLogNotFound,
  setClientQueryLog,
  setMismatchIndex,
  setMatchIndex,
  setBlocklistIndex,
  setOnMismatchTab,
  setOnMatchTab,
  setOnBlocklistTab,
  setNavigationData,
} = clientReviewSlice.actions;

export const getClientQueryLog =
  (requestId: string, getToken: () => Promise<string>): AppThunk =>
  async (dispatch) => {
    dispatch(setLoader(true));
    const token = await getToken();
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/nebulaReview/v1/clientLogs?requestId=${requestId}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        dispatch(setDedupeReviewPageHits(nullReviewPageHits));
        dispatch(setDedupeReviewPageData(nullReviewPageData));
        dispatch(setFaceAuthReviewPageData(nullFaceAuthData));
        dispatch(setLogNotFound(false));
        dispatch(setClientQueryLog(res.data));
      }
    } catch (err: any) {
      dispatch(setLogNotFound(true));
      dispatch(
        addAlert({
          message: err.response.data || 'Failed to get logs',
          messageType: 'FAILURE',
        })
      );
    }
    dispatch(setLoader(false));
  };

export const selectLoader = (state: RootState): boolean =>
  state.clientreview.loader;
export const selectLogNotFound = (state: RootState): boolean =>
  state.clientreview.isLogNotFound;
export const selectOnMismatchTab = (state: RootState): boolean =>
  state.clientreview.onMismatchTab;
export const selectOnMatchTab = (state: RootState): boolean =>
  state.clientreview.onMatchTab;
export const selectOnBlocklistTab = (state: RootState): boolean =>
  state.clientreview.onBlocklistTab;
export const selectClientQueryLog = (
  state: RootState
): ClientQueryLog | ClientFaceAuthLog => state.clientreview.queryLog;
export const selectNavigationData = (state: RootState): Navigation =>
  state.clientreview.navigation;

export default clientReviewSlice.reducer;
