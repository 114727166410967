/* eslint-disable no-nested-ternary */
import React from 'react';
import na from '../../../assets/sample/na.jpg';
import style from './faceAuthComparison.module.css';
import Tag from '../tag';
import Label from '../label';
import {
  FaceAuthHit,
  FaceAuthSelfieComparisonData,
} from '../../../containers/faceAuthReviewPage/interface';

type Props = {
  hit: FaceAuthHit;
  selfieComparisonData: FaceAuthSelfieComparisonData;
};

const FaceAuthComparison = ({ hit, selfieComparisonData }: Props) => {
  return (
    <div className={style.container_lg}>
      <div className={style.tag_nav_bar}>
        <div className={style.tag_bar}>
          <Tag
            itemKey="Request ID:"
            value={selfieComparisonData.requestId}
            borderColor="#28a4bc"
            color="#28a4bc"
            valueColor="#28a4bc"
          />
          <Tag
            itemKey="Timestamp:"
            value={selfieComparisonData.timestamp}
            borderColor="#28a4bc"
            color="#28a4bc"
            valueColor="#28a4bc"
          />
        </div>
      </div>
      <div className={style.container_wrapper}>
        <div className={style.container_heading}>Selfie Comparison</div>
        <div className={style.container_content}>
          <div className={style.query}>
            <span>Query Selfie</span>
            <div className={style.query_wrapper}>
              <img
                className={style.img_size}
                src={
                  selfieComparisonData.images.selfieImages.original === 'NA'
                    ? na
                    : selfieComparisonData.images.selfieImages.original
                }
                alt=""
              />
            </div>
          </div>
          <div className={style.db}>
            <span>Database Selfie</span>
            <div className={style.db_wrapper}>
              <img
                className={style.img_size}
                src={
                  hit.images.selfieImages.original === 'NA'
                    ? na
                    : hit.images.selfieImages.original
                }
                alt=""
              />
            </div>
          </div>
          <div className={style.result}>
            <Label
              itemKey="Unique ID"
              value={selfieComparisonData.transactionId}
              padding="0.7rem 1rem"
              fontWeight={700}
            />
            <Label
              itemKey="Selfie Match"
              value={
                hit.match === 'NA'
                  ? hit.match
                  : hit.match === 'Yes'
                  ? 'tick'
                  : 'cross'
              }
              fontSize={hit.match === 'NA' ? '1rem' : '1.5rem'}
              padding="0.7rem 1rem"
              borderColor={
                hit.match === 'NA'
                  ? '#ededf4'
                  : hit.match === 'Yes'
                  ? '#00A510'
                  : '#FF0000'
              }
              valueColor={
                hit.match === 'NA'
                  ? '#000000'
                  : hit.match === 'Yes'
                  ? '#00A510'
                  : '#FF0000'
              }
              color={
                hit.match === 'NA'
                  ? '#000000'
                  : hit.match === 'Yes'
                  ? '#00A510'
                  : '#FF0000'
              }
              fontWeight={800}
              backgroundColor={
                hit.match === 'NA'
                  ? '#fafaff'
                  : hit.match === 'Yes'
                  ? 'rgba(0,156,16,10%)'
                  : 'rgba(255,0,0,10%)'
              }
            />
            <Label
              itemKey="Liveness"
              value={
                selfieComparisonData.check.selfie.quality.liveness === 'NA'
                  ? selfieComparisonData.check.selfie.quality.liveness
                  : selfieComparisonData.check.selfie.quality.liveness ===
                    'Pass'
                  ? 'tick'
                  : 'cross'
              }
              fontSize={
                selfieComparisonData.check.selfie.quality.liveness === 'NA'
                  ? '1rem'
                  : '1.5rem'
              }
              padding="0.7rem 1rem"
              borderColor={
                selfieComparisonData.check.selfie.quality.liveness === 'NA'
                  ? '#ededf4'
                  : selfieComparisonData.check.selfie.quality.liveness ===
                    'Pass'
                  ? '#00A510'
                  : '#FF0000'
              }
              valueColor={
                selfieComparisonData.check.selfie.quality.liveness === 'NA'
                  ? '#000000'
                  : selfieComparisonData.check.selfie.quality.liveness ===
                    'Pass'
                  ? '#00A510'
                  : '#FF0000'
              }
              color={
                selfieComparisonData.check.selfie.quality.liveness === 'NA'
                  ? '#000000'
                  : selfieComparisonData.check.selfie.quality.liveness ===
                    'Pass'
                  ? '#00A510'
                  : '#FF0000'
              }
              fontWeight={800}
              backgroundColor={
                selfieComparisonData.check.selfie.quality.liveness === 'NA'
                  ? '#fafaff'
                  : selfieComparisonData.check.selfie.quality.liveness ===
                    'Pass'
                  ? 'rgba(0,156,16,10%)'
                  : 'rgba(255,0,0,10%)'
              }
            />
            <Label
              itemKey="Selfie Quality"
              value={
                selfieComparisonData.check.selfie.quality.faceQuality === 'NA'
                  ? selfieComparisonData.check.selfie.quality.faceQuality
                  : selfieComparisonData.check.selfie.quality.faceQuality ===
                    'Pass'
                  ? 'tick'
                  : 'cross'
              }
              fontSize={
                selfieComparisonData.check.selfie.quality.faceQuality === 'NA'
                  ? '1rem'
                  : '1.5rem'
              }
              padding="0.7rem 1rem"
              borderColor={
                selfieComparisonData.check.selfie.quality.faceQuality === 'NA'
                  ? '#ededf4'
                  : selfieComparisonData.check.selfie.quality.faceQuality ===
                    'Pass'
                  ? '#00A510'
                  : '#FF0000'
              }
              valueColor={
                selfieComparisonData.check.selfie.quality.faceQuality === 'NA'
                  ? '#000000'
                  : selfieComparisonData.check.selfie.quality.faceQuality ===
                    'Pass'
                  ? '#00A510'
                  : '#FF0000'
              }
              color={
                selfieComparisonData.check.selfie.quality.faceQuality === 'NA'
                  ? '#000000'
                  : selfieComparisonData.check.selfie.quality.faceQuality ===
                    'Pass'
                  ? '#00A510'
                  : '#FF0000'
              }
              fontWeight={800}
              backgroundColor={
                selfieComparisonData.check.selfie.quality.faceQuality === 'NA'
                  ? '#fafaff'
                  : selfieComparisonData.check.selfie.quality.faceQuality ===
                    'Pass'
                  ? 'rgba(0,156,16,10%)'
                  : 'rgba(255,0,0,10%)'
              }
            />
          </div>
          <div className={style.result}>
            <Label
              itemKey="Match Score"
              value={hit.matchScore === 'NA' ? 'NA' : hit.matchScore}
              fontWeight={700}
            />
            {selfieComparisonData.error === 'NA' ? null : (
              <Label
                itemKey="Error"
                value={selfieComparisonData.error}
                fontWeight={700}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaceAuthComparison;
