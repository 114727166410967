export const nullHitData = {
  reason: 'N/A',
  name: 'N/A',
  dob: 'N/A',
  nid: 'N/A',
  matchScore: { selfie: 0, idFaceCrop: 0 },
  aiDecision: {
    selfie: 'N/A',
    idFace: 'N/A',
    idNumber: 'N/A',
  },
  idImages: {
    ssd: 'N/A',
    fraasCrop: 'N/A',
  },
  selfieImages: {
    original: 'N/A',
  },
};

export const nullQueryLog = {
  images: {
    idImages: {
      ssd: 'N/A',
      original: 'N/A',
      fraasCrop: 'N/A',
    },
    selfieImages: {
      original: 'N/A',
      fraasCrop: 'N/A',
    },
  },
  hits: { mismatches: [], matches: [] },
  count: { total: { matches: 0, mismatches: 0 } },
  input: {
    ocr: {
      dob: { conf: 0, value: 'N/A', toBeReviewed: 'N/A' },
      id: { value: 'N/A', conf: 0, toBeReviewed: 'N/A' },
      name: { value: 'N/A', conf: 0, toBeReviewed: 'N/A' },
    },
    user: {
      name: 'N/A',
      dob: 'N/A',
      idNumber: 'N/A',
      applicationId: 'N/A',
    },
    query: {
      name: 'N/A',
      dob: 'N/A',
      idNumber: 'N/A',
    },
  },
  check: {
    verifyPair: { match: false },
    selfie: { quality: { blur: { overall: false } } },
    verify: { value: false },
    enrol: { value: false },
  },
  requestId: 'N/A',
  clientId: 'N/A',
  endpoint: 'N/A',
  aggregatorUrl: 'N/A',
};

export const initialNavigation = {
  mismatches: {
    currIndex: 0,
    totalIndex: 0,
  },
  matches: {
    currIndex: 0,
    totalIndex: 0,
  },
  blocklist: {
    currIndex: 0,
    totalIndex: 0,
  },
  buckets: ['mismatches', 'matches', 'blocklist'],
};
