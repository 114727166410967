import React from 'react';

import style from './comparisonContainer.module.css';
import Verdict from '../verdict';
import { ComparisonData } from '../../../containers/dedupeReviewPage/interface';
import { ClientReviewHit } from '../../../containers/clientReviewPage/interface';
import ClientSelfieComparison from '../clientSelfieComparison';
import ClientIdDetailsComparison from '../clientIdDetailsComparison';
import ClientIdFaceComparison from '../clientIdFaceComparison';

type Props = {
  requestId: string;
  data: ComparisonData;
  suspicious: boolean;
  hit: ClientReviewHit;
  selfieOnlyQC: boolean;
  selfieOnlyQCWithIdDetails: boolean;
  faceSearchQC: boolean;
  customClientChangeCathay: boolean;
};

const ComparisonContainer = ({
  requestId,
  data,
  suspicious,
  hit,
  selfieOnlyQC,
  selfieOnlyQCWithIdDetails,
  faceSearchQC,
  customClientChangeCathay,
}: Props) => {
  return (
    <div className={style.container}>
      <div className={`${style.result_wrapper} ${style.row_1}`}>
        <ClientSelfieComparison
          hit={hit}
          data={data.selfieComparison}
          faceSearchQC={faceSearchQC}
          customClientChangeCathay={customClientChangeCathay}
          enrolStatus={data.enrolStatus}
          enrolReason={data.enrolReason}
        />
        {faceSearchQC ? null : (
          <ClientIdDetailsComparison
            hit={hit}
            data={data.idDetailsComparison}
            selfieOnlyQC={selfieOnlyQC}
            selfieOnlyQCWithIdDetails={selfieOnlyQCWithIdDetails}
          />
        )}
      </div>
      {selfieOnlyQC ? null : (
        <div className={`${style.result_wrapper} ${style.row_2}`}>
          <ClientIdFaceComparison hit={hit} data={data.idFaceComparison} />
          <Verdict hit={hit} requestId={requestId} suspicious={suspicious} />
        </div>
      )}
    </div>
  );
};

export default ComparisonContainer;
