import React from 'react';
import { NavLink } from 'react-router-dom';
import { RiDashboard3Line, RiBookletLine } from 'react-icons/ri';
import { FiLogOut } from 'react-icons/fi';
import { VscPreview } from 'react-icons/vsc';

import style from './sideBar.module.css';

type Props = {
  userAccessLevel: string;
  signOut: () => void;
};

const SideBar = ({ userAccessLevel, signOut }: Props) => {
  const handleLogOut = (e: any) => {
    e.preventDefault();
    signOut();
  };
  if (!userAccessLevel) {
    return <div className={style._} />;
  }
  return (
    <div>
      <div className={style._}>
        <div className={`${style.section} ${style.left}`}>
          <div className={style.logo_wrapper}>
            <img className={style.logo} alt="Hyperverge Logo" />
          </div>
          <div className={style.menu_items}>
            <div className={style.menu_items_wrapper}>
              {userAccessLevel[0] === '1' && (
                <NavLink
                  exact
                  to="/home"
                  className={style.menu_item}
                  activeClassName={`${style.menu_item} ${style.menu_item_selected}`}
                >
                  <div className={style.menu_item_wrapper}>
                    <RiDashboard3Line />
                    <span className={style.menu_item_text}>Dashboard</span>
                  </div>
                </NavLink>
              )}
              {userAccessLevel[1] === '1' && (
                <a href="/documentation" className={style.menu_item}>
                  <div className={style.menu_item_wrapper}>
                    <RiBookletLine />
                    <span className={style.menu_item_text}>Documentation</span>
                  </div>
                </a>
              )}
              {userAccessLevel[3] === '1' && (
                <a href="/reviewportal" className={style.menu_item}>
                  <div className={style.menu_item_wrapper}>
                    <VscPreview />
                    <span className={style.menu_item_text}>Review Portal</span>
                  </div>
                </a>
              )}
            </div>
          </div>
          <div className={style.root_menu_items}>
            <NavLink
              exact
              to="/home"
              onClick={handleLogOut}
              className={style.menu_item}
              activeClassName={`${style.menu_item} ${style.menu_item_selected}`}
            >
              <div className={style.menu_item_wrapper}>
                <FiLogOut />
                <span className={style.menu_item_text}>Log Out</span>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      <div className={`${style.section} ${style.right}`} />
    </div>
  );
};

export default SideBar;
