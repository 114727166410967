export const nullReviewPageData = {
  tagBar: {
    applicationId: 'N/A',
    qualityCheck: 'N/A',
    selfieIdMatch: 'N/A',
    aggregatorUrl: 'N/A',
  },
  results: {
    selfieComparison: {
      capturedSelfie: 'NA',
      databaseSelfie: 'NA',
      selfieMatch: 'N/A',
      matchScore: 'N/A',
    },
    idFaceComparison: {
      capturedIdFace: 'NA',
      databaseIdFace: 'NA',
      IdFaceMatch: 'N/A',
      matchScore: 'N/A',
    },
    idDetailsComparison: {
      queryDetails: {
        idNumber: 'N/A',
        fullName: 'N/A',
        dob: 'N/A',
      },
      dbDetails: {
        idNumber: 'N/A',
        fullName: 'N/A',
        dob: 'N/A',
      },
      matchDetails: {
        idNumber: 'N/A',
        fullName: 'N/A',
        dob: 'N/A',
      },
    },
    verdict: {
      reason: 'N/A',
    },
    enrolStatus: false,
    enrolReason: 'N/A',
  },
  navigation: {
    mismatches: {
      currIndex: 0,
      totalIndex: 0,
    },
    matches: {
      currIndex: 0,
      totalIndex: 0,
    },
    blocklist: {
      currIndex: 0,
      totalIndex: 0,
    },
    buckets: ['mismatches', 'matches', 'blocklist'],
  },
  suspicious: false,
  selfieOnlyQC: false,
  selfieOnlyQCWithIdDetails: false,
  faceSearchQC: false,
  clientId: 'N/A',
  timestamp: 'NA',
  customClientChangeCathay: false,
  displayHitDob: false,
};

export const nullHit = {
  reason: 'N/A',
  name: 'N/A',
  dob: 'N/A',
  nid: 'N/A',
  matchScore: {
    selfie: 0,
    idFaceCrop: 0,
  },
  aiDecision: {
    selfie: 'N/A',
    idFace: 'N/A',
    idNumber: 'N/A',
    name: 'N/A',
    dob: 'N/A',
  },
  idImages: {
    fraasCrop: 'N/A',
  },
  selfieImages: {
    original: 'N/A',
  },
};

export const nullReviewPageHits = {
  matches: [nullHit],
  mismatches: [nullHit],
  blocklist: [nullHit],
};
