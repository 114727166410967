// This project is setup using redux-toolkit which includes immer by default.
// Hence state is mutable.

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { batch } from 'react-redux';
import { addAlert } from '../../components/clientComponents/alert/alertSlice';
import { AppThunk, RootState } from '../../store';
import { DocumentationPageState } from './interface';

const initialState: DocumentationPageState = {
  isDocLoading: true,
  orionLink: '',
  vietnamkycLink: '',
  philippinesLink: '',
  docError: null,
};

export const dedupeReviewSlice = createSlice({
  name: 'documentationpage',
  initialState,
  reducers: {
    setDocLoader: (state, action: PayloadAction<boolean>) => {
      state.isDocLoading = action.payload;
    },
    setDocLink: (state, action: PayloadAction<string>) => {
      state.isDocLoading = false;
      state.orionLink = action.payload;
      state.vietnamkycLink =
        'https://developer.hyperverge.co/vietnam?k=0a8b7f99b9054e19fda8d06c8a65dddc';
      state.philippinesLink =
        'https://developer.hyperverge.co/philippines/kyc/-?k=0a8b7f99b9054e19fda8d06c8a65dddc';
      state.docError = '';
    },
    setDocError: (state, action: PayloadAction<string>) => {
      state.isDocLoading = false;
      state.docError = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    emptyDocLink: (state, action: PayloadAction<void>) => {
      state.orionLink = '';
      state.vietnamkycLink = '';
      state.philippinesLink = '';
    },
  },
});

export const { setDocLoader, setDocLink, setDocError, emptyDocLink } =
  dedupeReviewSlice.actions;

export const getDocsLink =
  (): AppThunk => async (dispatch, getState, getFirebase) => {
    dispatch(setDocLoader(true));
    const firebaseInstance = getFirebase();
    try {
      const token = await firebaseInstance.auth().currentUser?.getIdToken(true);
      if (token) {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/nebulaDocsAnalytics/docs/token/create`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 200) {
          dispatch(setDocLink(res.data?.doc_orion_url));
        }
      } else {
        batch(() => {
          dispatch(setDocError('Error in getting Documentation Link'));
          dispatch(
            addAlert({
              message: 'Error in getting Documentation Link',
              messageType: 'FAILURE',
            })
          );
        });
      }
    } catch (err) {
      batch(() => {
        dispatch(setDocError('Error in getting Documentation Link'));
        dispatch(
          addAlert({
            message: 'Documentation Data Not Found',
            messageType: 'FAILURE',
          })
        );
      });
    }
  };

export const selectDocLoader = (state: RootState): boolean =>
  state.documentationpage.isDocLoading;
export const selectOrionLink = (state: RootState): string =>
  state.documentationpage.orionLink;
export const selectVietKYCLink = (state: RootState): string =>
  state.documentationpage.vietnamkycLink;
export const selectPhilKYCLink = (state: RootState): string =>
  state.documentationpage.philippinesLink;
export const selectDocError = (state: RootState): string | null =>
  state.documentationpage.docError;

export default dedupeReviewSlice.reducer;
