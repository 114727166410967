const nullFaceAuthData = {
  requestId: 'NA',
  clientId: 'NA',
  endpoint: '/v2/faceauth',
  aggregatorUrl: 'NA',
  transactionId: 'NA',
  uid: 'NA',
  uidType: 'NA',
  timestamp: 'NA',
  images: {
    selfieImages: {
      original: 'N/A',
      fraasCrop: 'N/A',
    },
  },
  check: {
    selfie: {
      quality: {
        faceQuality: 'NA',
        liveness: 'NA',
      },
    },
  },
  hit: {
    requestId: 'NA',
    transactionId: 'NA',
    clientId: 'NA',
    distance: 'NA',
    match: 'NA',
    matchScore: 'NA',
    images: {
      selfieImages: {
        original: 'N/A',
        fraasCrop: 'N/A',
      },
    },
  },
  error: 'NA',
};

export default nullFaceAuthData;
