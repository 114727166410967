import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import DedupeReviewPage from '../dedupeReviewPage';
import {
  getClientQueryLog,
  selectClientQueryLog,
  selectLoader,
  selectLogNotFound,
  selectNavigationData,
  selectOnBlocklistTab,
  selectOnMatchTab,
  selectOnMismatchTab,
  setBlocklistIndex,
  setMatchIndex,
  setMismatchIndex,
  setNavigationData,
  setOnBlocklistTab,
  setOnMatchTab,
  setOnMismatchTab,
} from './clientReviewPageSlice';
import { ClientQueryLog } from './interface';
import style from './clientReviewPage.module.css';
import BigLoader from '../../components/clientComponents/bigLoader';
import FaceAuthReviewPage from '../faceAuthReviewPage';

const ClientReviewPage = () => {
  const dispatch = useDispatch();
  const firebaseAuth = useSelector((state: any) => state.firebase.auth);
  const firebase = useFirebase();
  const { requestId } = useParams<{ requestId: string }>();
  const loader = useSelector(selectLoader);

  useEffect(() => {
    const getToken = async (): Promise<string> => {
      if (!firebaseAuth.uid) return '';
      try {
        const token = await firebase.auth().currentUser?.getIdToken(true);
        return token || '';
      } catch (err) {
        return '';
      }
    };
    dispatch(getClientQueryLog(requestId, getToken));
  }, [dispatch, firebaseAuth, firebase, requestId]);

  const queryLogData = useSelector(selectClientQueryLog) as ClientQueryLog;

  useEffect(() => {
    const isFaceAuthReview = queryLogData.endpoint === '/faceauth';
    if (!isFaceAuthReview) {
      const totalMatches = queryLogData.count.total.matches;
      const totalMismatches = queryLogData.count.total.mismatches;
      const totalBlocklist = queryLogData.count.total.blocklist;
      const selfieOnlyQCFlag = ['/v2/search', '/v2/search/face'].includes(
        queryLogData.endpoint
      );

      const navigation = {
        matches: {
          currIndex: totalMatches ? 1 : 0,
          totalIndex: totalMatches,
        },
        mismatches: {
          currIndex: totalMismatches ? 1 : 0,
          totalIndex: totalMismatches,
        },
        blocklist: {
          currIndex: totalBlocklist ? 1 : 0,
          totalIndex: totalBlocklist || 0,
        },
        buckets: selfieOnlyQCFlag
          ? ['mismatches', 'matches', 'blocklist']
          : ['mismatches', 'matches'],
      };
      dispatch(setNavigationData(navigation));
    }
  }, [dispatch, queryLogData]);

  const onMismatchTab = useSelector(selectOnMismatchTab);
  const onMatchTab = useSelector(selectOnMatchTab);
  const onBlocklistTab = useSelector(selectOnBlocklistTab);
  const navigationData = useSelector(selectNavigationData);
  const isLogNotFound = useSelector(selectLogNotFound);

  const changeCurrIndex = (index: number) => {
    if (onMismatchTab) {
      dispatch(setMismatchIndex(index));
    } else if (onMatchTab) {
      dispatch(setMatchIndex(index));
    } else {
      dispatch(setBlocklistIndex(index));
    }
  };

  const toggleCategory = (togglevalue: string) => {
    if (togglevalue === 'match') {
      dispatch(setOnMismatchTab(false));
      dispatch(setOnBlocklistTab(false));
      dispatch(setOnMatchTab(true));
    } else if (togglevalue === 'mismatch') {
      dispatch(setOnBlocklistTab(false));
      dispatch(setOnMatchTab(false));
      dispatch(setOnMismatchTab(true));
    } else {
      dispatch(setOnMatchTab(false));
      dispatch(setOnMismatchTab(false));
      dispatch(setOnBlocklistTab(true));
    }
  };

  const shiftTabsAndIndex = (e: any) => {
    const mismatchesData = navigationData.mismatches;
    const matchesData = navigationData.matches;
    const blocklistData = navigationData.blocklist;
    if (e.key === 'ArrowLeft') {
      if (
        onMismatchTab &&
        !(mismatchesData.totalIndex === 0 || mismatchesData.currIndex === 1)
      ) {
        changeCurrIndex(mismatchesData.currIndex - 1);
      } else if (onMatchTab) {
        if (!(matchesData.totalIndex === 0 || matchesData.currIndex === 1)) {
          changeCurrIndex(matchesData.currIndex - 1);
        } else if (mismatchesData.totalIndex > 0) {
          dispatch(setOnMismatchTab(true));
        }
      } else if (onBlocklistTab) {
        if (
          !(blocklistData.totalIndex === 0 || blocklistData.currIndex === 1)
        ) {
          changeCurrIndex(blocklistData.currIndex - 1);
        } else if (matchesData.totalIndex > 0) {
          dispatch(setOnMatchTab(true));
        } else if (mismatchesData.totalIndex > 0) {
          dispatch(setOnMismatchTab(true));
        }
      }
    } else if (e.key === 'ArrowRight') {
      if (onMismatchTab) {
        if (mismatchesData.totalIndex > mismatchesData.currIndex) {
          changeCurrIndex(mismatchesData.currIndex + 1);
        } else if (matchesData.totalIndex > 0) {
          dispatch(setOnMatchTab(true));
        } else if (blocklistData.totalIndex > 0) {
          dispatch(setOnBlocklistTab(true));
        }
      } else if (onMatchTab) {
        if (matchesData.totalIndex > matchesData.currIndex) {
          changeCurrIndex(matchesData.currIndex + 1);
        } else if (blocklistData.totalIndex > 0) {
          dispatch(setOnBlocklistTab(true));
        }
      } else if (
        onBlocklistTab &&
        matchesData.totalIndex > matchesData.currIndex
      ) {
        changeCurrIndex(blocklistData.currIndex + 1);
      }
    }
  };

  if (loader) {
    return (
      <div className={style.loader_wrapper}>
        <BigLoader />
      </div>
    );
  }

  const isFaceAuthReviewFlag = queryLogData.endpoint === '/faceauth';

  const resultRender = isFaceAuthReviewFlag ? (
    <FaceAuthReviewPage />
  ) : (
    <DedupeReviewPage
      shiftTabsAndIndex={shiftTabsAndIndex}
      toggleCategory={toggleCategory}
      changeCurrIndex={changeCurrIndex}
      requestId={requestId}
    />
  );

  return isLogNotFound ? null : resultRender;
};

export default ClientReviewPage;
