import React from 'react';
import ImageIcon from '@material-ui/icons/Image';
import './image.css';

type Props = {
  height: number;
  width?: number;
  src: string;
  alt: string;
  zoom?: boolean;
  border?: 'red' | 'green';
  type?: 'left' | 'right' | undefined;
};

const Image = ({
  height,
  width,
  src,
  alt,
  border = undefined,
  zoom = undefined,
  type = undefined,
}: Props) => {
  const iconStyle = {
    fontSize: `${height}px`,
  };

  const render =
    src === 'NA' || src === 'N/A' ? (
      <div className="Image-Fallback-Container">
        <ImageIcon color="primary" style={iconStyle} />
        <p className="Image-Fallback-Text">NA</p>
      </div>
    ) : (
      <img
        className={`${border} ${zoom ? 'zoom' : null} ${zoom ? type : null}`}
        src={src}
        height={`${height}px`}
        width={`${width}px`}
        alt={alt}
      />
    );

  return render;
};

export default Image;
