import React from 'react';
import { getResultFromBoolean } from '../../utils/validation';
import './applicationBar.css';

type Props = {
  requestId: string;
  clientId: string;
  verifyStatus: boolean;
  enrolment: boolean;
  selfieBlur: boolean;
  verifyPair: boolean;
  applicationId: string;
};

const ApplicationBar = ({
  requestId,
  clientId,
  verifyStatus,
  enrolment,
  selfieBlur,
  verifyPair,
  applicationId,
}: Props) => {
  return (
    <div className="ApplicationBar">
      <p className="ApplicationBar-Title">
        RequestId: <span className="ApplicationBar-Content">{requestId}</span>
      </p>
      <p className="ApplicationBar-Title">
        ClientId: <span className="ApplicationBar-Content">{clientId}</span>
      </p>
      <p className="ApplicationBar-Title">
        Verify Status:{' '}
        <span className="ApplicationBar-Content">
          {getResultFromBoolean(verifyStatus)}
        </span>
      </p>
      <p className="ApplicationBar-Title">
        Enrolment:{' '}
        <span className="ApplicationBar-Content">
          {getResultFromBoolean(enrolment)}
        </span>
      </p>
      <p className="ApplicationBar-Title">
        SelfieBlur:{' '}
        <span className="ApplicationBar-Content">
          {getResultFromBoolean(selfieBlur)}
        </span>
      </p>
      <p className="ApplicationBar-Title">
        Query Selfie-Id Match:{' '}
        <span className="ApplicationBar-Content">
          {getResultFromBoolean(verifyPair)}
        </span>
      </p>
      <p className="ApplicationBar-Title">
        ApplicationId:{' '}
        <span className="ApplicationBar-Content">{applicationId || 'NA'}</span>
      </p>
    </div>
  );
};

export default ApplicationBar;
