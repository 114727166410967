import React from 'react';
import style from './loader.module.css';

const Loader = () => {
  return (
    <div className={style._}>
      <span />
    </div>
  );
};
export default Loader;
