/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import axios from 'axios';
import { AiFillDelete } from 'react-icons/ai';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import { useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

import Tag from '../tag';
import style from './tagBar.module.css';
import { TagBarData } from '../../../containers/dedupeReviewPage/interface';
import {
  ClientReviewHit,
  Navigation,
} from '../../../containers/clientReviewPage/interface';
import NavigationBar from '../navigationBar';

type Props = {
  data: TagBarData;
  navigation: Navigation;
  changeCurrIndex: (index: number) => void;
  toggleCategory: (togglevalue: string) => void;
  onMismatchTab: boolean;
  onMatchTab: boolean;
  onBlocklistTab: boolean;
  selfieOnlyQC: boolean;
  customClientChangeCathay: boolean;
  displayHitDob: boolean;
  timestamp: string | Date;
  requestId: string;
  hit: ClientReviewHit;
};

const TagBar = ({
  data,
  navigation,
  changeCurrIndex,
  onMismatchTab,
  onMatchTab,
  onBlocklistTab,
  toggleCategory,
  selfieOnlyQC,
  customClientChangeCathay,
  displayHitDob,
  timestamp,
  requestId,
  hit,
}: Props) => {
  const firebaseAuth = useSelector((state: any) => state.firebase.auth);
  const firebase = useFirebase();

  const [loader, setLoader] = useState(false);
  const [deletePass, setDeletePass] = useState(false);
  const [deleteFail, setDeleteFail] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(
    'Failed to delete application'
  );

  const getToken = async (): Promise<string> => {
    if (!firebaseAuth.uid) return '';
    try {
      const token = await firebase.auth().currentUser?.getIdToken(true);
      return token || '';
    } catch (err) {
      return '';
    }
  };

  const deleteHandler = async () => {
    setLoader(true);
    try {
      const token = await getToken();
      await axios.post(
        `${data.aggregatorUrl}/v2/application/portal/delete`,
        { transactionId: data.applicationId, requestId },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      setDeletePass(true);
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      if (err?.response?.status === 404) {
        setDeleteFail(true);
        setDeleteMessage("Couldn't find application");
      } else if (err?.response?.status === 401) {
        setDeleteFail(true);
        setDeleteMessage('Unauthorized');
      } else {
        setDeleteFail(true);
        setDeleteMessage('Failed to delete application');
      }
    }
  };

  const submit = async () => {
    confirmAlert({
      message: 'This action will delete the application. Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await deleteHandler();
          },
        },
        {
          label: 'No',
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div className={style.tag_nav_bar}>
      <div className={style.tag_bar}>
        {data.applicationId && (
          <Tag
            itemKey={customClientChangeCathay ? 'Unique ID:' : 'ApplicationID:'}
            value={data.applicationId}
            borderColor="#28a4bc"
            color="#28a4bc"
            valueColor="#28a4bc"
          />
        )}

        {customClientChangeCathay ? (
          <Tag
            itemKey="Request ID:"
            value={requestId}
            borderColor="#28a4bc"
            color="#28a4bc"
            valueColor="#28a4bc"
          />
        ) : null}

        {customClientChangeCathay ? (
          <Tag
            itemKey="Timestamp:"
            value={timestamp}
            borderColor="#28a4bc"
            color="#28a4bc"
            valueColor="#28a4bc"
          />
        ) : null}

        {displayHitDob && hit.enrolDate ? (
          <Tag
            itemKey="Hit Enrol Date:"
            value={hit.enrolDate}
          />
        ) : null}

        {data.qualityCheck === undefined ||
        data.qualityCheck === 'NA' ? null : selfieOnlyQC ? (
          <Tag
            itemKey="Quality Checks"
            value={
              data.qualityCheck === undefined
                ? 'N/A'
                : !data.qualityCheck
                ? 'tick'
                : 'cross'
            }
            valueColor={
              data.qualityCheck === undefined
                ? '#00000'
                : !data.qualityCheck
                ? '#00A510'
                : '#FF0000'
            }
          />
        ) : (
          <Tag
            itemKey="Quality Checks"
            value={
              data.qualityCheck === undefined
                ? 'N/A'
                : data.qualityCheck
                ? 'tick'
                : 'cross'
            }
            valueColor={
              data.qualityCheck === undefined
                ? '#00000'
                : data.qualityCheck
                ? '#00A510'
                : '#FF0000'
            }
          />
        )}
        {selfieOnlyQC
          ? null
          : data.selfieIdMatch && (
              <Tag
                itemKey="Selfie-ID Match"
                value={
                  data.selfieIdMatch === 'N/A'
                    ? 'N/A'
                    : data.selfieIdMatch
                    ? 'tick'
                    : 'cross'
                }
                valueColor={
                  data.selfieIdMatch === 'N/A'
                    ? '#00000'
                    : data.selfieIdMatch
                    ? '#00A510'
                    : '#FF0000'
                }
              />
            )}
      </div>
      {customClientChangeCathay ? null : (
        <div>
          <div>
            <NavigationBar
              data={navigation}
              onMismatchTab={onMismatchTab}
              onMatchTab={onMatchTab}
              onBlocklistTab={onBlocklistTab}
              changeCurrIndex={changeCurrIndex}
              toggleCategory={toggleCategory}
            />
            <span
              style={{
                color: '#F2041A',
                fontSize: '1.5rem',
                padding: '5px',
                cursor: 'pointer',
              }}
            >
              <AiFillDelete
                onClick={submit}
                onMouseOver={({ target }: { target: any }) => {
                  target.style.color = '#E35C69';
                }}
                onMouseOut={({ target }: { target: any }) => {
                  target.style.color = '#F2041A';
                }}
              />
            </span>
          </div>
          <Snackbar
            open={loader}
            autoHideDuration={1000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert severity="info">Making delete request</Alert>
          </Snackbar>

          <Snackbar
            open={deletePass}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => {
              setDeletePass(false);
            }}
          >
            <Alert severity="success">Successfully deleted application</Alert>
          </Snackbar>

          <Snackbar
            open={deleteFail}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => {
              setDeleteFail(false);
            }}
          >
            <Alert severity="error">{deleteMessage}</Alert>
          </Snackbar>
        </div>
      )}
    </div>
  );
};

export default TagBar;
