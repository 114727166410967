import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import style from './navBar.module.css';

const NavBar = () => {
  const history = useHistory();

  const [searchReqId, setSearchReqtId] = useState('');

  // Handlers
  const selectRequestIdHandler = (e: any) => {
    e.target.select();
  };
  const changeRequestIdHandler = (e: any) => {
    setSearchReqtId(e.target.value);
  };

  const getRequestDataHandler = () => {
    history.push(`/reviewportal/${searchReqId}`);
  };
  return (
    <div className={style.nav_bar}>
      <div className={style.search_box}>
        <input
          autoFocus
          value={searchReqId}
          onClick={selectRequestIdHandler}
          onChange={changeRequestIdHandler}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              getRequestDataHandler();
            }
          }}
          placeholder="Request ID or Transaction ID"
        />
        <button
          onClick={() => {
            getRequestDataHandler();
          }}
          type="submit"
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default NavBar;
