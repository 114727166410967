import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import style from './loginPage.module.css';

import Loader from '../../components/clientComponents/loader';
import Alert from '../../components/clientComponents/alert';
import {
  selectEmail,
  selectLoading,
  selectPassword,
  setEmail,
  setPassword,
  signIn,
} from './loginSlice';

const LoginPage = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectLoading);
  const email = useSelector(selectEmail);
  const password = useSelector(selectPassword);
  const firebaseAuth = useSelector((state: any) => state.firebase.auth);

  const handleEmailChange = (e: any) => {
    dispatch(setEmail(e.target.value));
  };

  const handlePasswordChange = (e: any) => {
    dispatch(setPassword(e.target.value));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(signIn(email, password));
  };
  if (firebaseAuth.uid) {
    return <Redirect to="/reviewportal" />;
  }
  return (
    <div className={style._}>
      <div className={style.wrapper}>
        <h2>Sign In</h2>
        <input
          autoFocus
          id="email"
          value={email}
          onChange={handleEmailChange}
          className={style.input_box}
          placeholder="Email Id"
        />
        <input
          id="password"
          value={password}
          onChange={handlePasswordChange}
          className={style.input_box}
          placeholder="Password"
          type="password"
        />
        <button type="submit" onClick={handleSubmit} className={style.btn}>
          {isLoading ? <Loader /> : <span>Submit</span>}
        </button>
      </div>
      <Alert />
    </div>
  );
};

export default LoginPage;
