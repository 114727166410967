import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import style from './clientReviewRoutePage.module.css';
import ListPage from '../../components/clientComponents/listPage';
import ClientReviewPage from '../clientReviewPage';
import DocumentationPage from '../documentationPage';
import NavBar from '../../components/clientComponents/navBar';
import FCInitScript from '../../../FC';

const ClientPagesRoute = () => {
  const firebaseProfile = useSelector((state: any) => state.firebase.profile);
  const location = useLocation();

  FCInitScript();

  return (
    <div className={style._}>
      {location.pathname && location.pathname.includes('reviewportal') ? (
        <div className={`${style.section} ${style.header}`}>
          <div className={style.option_wrapper}>
            <NavBar />
          </div>
        </div>
      ) : null}
      <div className={`${style.section} ${style.content}`}>
        <Switch>
          {firebaseProfile.userAccessLevel &&
            firebaseProfile.userAccessLevel[3] === '1' && (
              <Route
                exact
                path="/reviewportal/:requestId"
                component={() => <ClientReviewPage />}
              />
            )}
          {firebaseProfile.userAccessLevel &&
            firebaseProfile.userAccessLevel[3] === '1' && (
              <Route
                exact
                path="/reviewportal"
                component={() => (
                  <ListPage
                    idListPage={
                      firebaseProfile.userAccessLevel.length === 8 &&
                      firebaseProfile.userAccessLevel[7] === '1'
                    }
                  />
                )}
              />
            )}
          {firebaseProfile.userAccessLevel &&
            firebaseProfile.userAccessLevel[1] === '1' && (
              <Route
                exact
                path="/documentation"
                component={() => (
                  <DocumentationPage
                    userAccessLevel={firebaseProfile.userAccessLevel}
                    documentationAccessLevel={
                      firebaseProfile.documentationAccessLevel
                    }
                  />
                )}
              />
            )}
          <Redirect to="/reviewportal" />
        </Switch>
      </div>
    </div>
  );
};

export default ClientPagesRoute;
