import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../components/clientComponents/card';
import kyc from '../../assets/sample/kyc_docs_icon.png';
import orion from '../../assets/sample/orion_docs_icon.png';
import style from './documentation.module.css';
import {
  getDocsLink,
  selectDocLoader,
  selectOrionLink,
  selectPhilKYCLink,
  selectVietKYCLink,
} from './documentationPageSlice';
import BigLoader from '../../components/clientComponents/bigLoader';

type Props = {
  documentationAccessLevel: string;
  userAccessLevel: string;
};

const DocumentationPage = ({
  userAccessLevel,
  documentationAccessLevel,
}: Props) => {
  const dispatch = useDispatch();
  const isDocLoading = useSelector(selectDocLoader);
  const orionLink = useSelector(selectOrionLink);
  const philippinesLink = useSelector(selectPhilKYCLink);
  const vietnamkycLink = useSelector(selectVietKYCLink);
  useEffect(() => {
    if (userAccessLevel && orionLink.length === 0) {
      dispatch(getDocsLink());
    }
  }, [dispatch, userAccessLevel, orionLink]);
  if (isDocLoading) {
    return (
      <div
        className={`${style._} ${style.center} ${style.display_flex}  ${style.flex_item}`}
      >
        <BigLoader />
      </div>
    );
  }

  return (
    <div className={style._}>
      <div className={`${style.section} ${style.content}`}>
        {documentationAccessLevel &&
          documentationAccessLevel.length > 0 &&
          documentationAccessLevel[0] === '1' && (
            <Card
              url={philippinesLink}
              backgroundColor="white"
              color="#9D00D7"
              heading="Philippines KYC"
              subHeading="Documentation for Philippines KYC"
              icon={<img src={kyc} height="60" alt="KYC" />}
            />
          )}
        {documentationAccessLevel &&
          documentationAccessLevel.length > 1 &&
          documentationAccessLevel[1] === '1' && (
            <Card
              url={vietnamkycLink}
              backgroundColor="white"
              color="#9D00D7"
              heading="Vietnam KYC"
              subHeading="Documentation for Vietnam KYC"
              icon={<img src={kyc} height="60" alt="KYC" />}
            />
          )}
        <Card
          url={orionLink}
          backgroundColor="white"
          color="#FF0040"
          heading="Orion"
          subHeading="Documentation for Orion Alliance"
          icon={<img src={orion} height="60" alt="Orion" />}
        />
      </div>
    </div>
  );
};

export default DocumentationPage;
