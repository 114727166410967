import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FaceAuthComparison from '../../components/clientComponents/faceAuthComparison';
import { selectClientQueryLog } from '../clientReviewPage/clientReviewPageSlice';
import { ClientFaceAuthLog } from '../clientReviewPage/interface';
import { getFaceAuthReviewPageData } from '../dedupeReviewPage/utils';
import {
  selectFaceAuthReviewPageData,
  setFaceAuthReviewPageData,
} from './faceAuthReviewPageSlice';
import { FaceAuthSelfieComparisonData } from './interface';

const FaceAuthReviewPage = () => {
  const dispatch = useDispatch();

  const queryLog = useSelector(selectClientQueryLog) as ClientFaceAuthLog;
  const faceAuthLog = useSelector(
    selectFaceAuthReviewPageData
  ) as ClientFaceAuthLog;

  useEffect(() => {
    const faceAuthReviewData = getFaceAuthReviewPageData(queryLog);
    dispatch(setFaceAuthReviewPageData(faceAuthReviewData));
  }, [dispatch, queryLog]);

  const selfieComparisonData: FaceAuthSelfieComparisonData = {
    images: {
      selfieImages: {
        original: faceAuthLog.images.selfieImages.original,
      },
    },
    check: faceAuthLog.check,
    error: faceAuthLog.error,
    transactionId: faceAuthLog.transactionId,
    timestamp: faceAuthLog.timestamp,
    requestId: faceAuthLog.requestId,
  };

  return (
    <div>
      <FaceAuthComparison
        hit={faceAuthLog.hit}
        selfieComparisonData={selfieComparisonData}
      />
    </div>
  );
};

export default FaceAuthReviewPage;
