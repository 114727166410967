/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';
import { FaRegCheckCircle } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';

import style from './clientIdDetailsComparison.module.css';
import { ClientReviewHit } from '../../../containers/clientReviewPage/interface';
import { IdDetailsComparisonI } from '../../../containers/dedupeReviewPage/interface';

type Props = {
  hit: ClientReviewHit;
  data: IdDetailsComparisonI;
  selfieOnlyQC: boolean;
  selfieOnlyQCWithIdDetails: boolean;
};

const ClientIdDetailsComparison = ({
  hit,
  data,
  selfieOnlyQC,
  selfieOnlyQCWithIdDetails,
}: Props) => {
  const displayIdDetails = selfieOnlyQCWithIdDetails || !selfieOnlyQC;

  const containerStyle = !displayIdDetails
    ? style.container_sm_lower
    : style.container_sm;
  return (
    <div className={containerStyle}>
      <div className={style.container_wrapper}>
        <div className={style.container_heading}>ID Details</div>
        <div className={style.container_content}>
          <table>
            <thead>
              <tr>
                <th className={style.col_s}> </th>
                <th className={style.col_l}>
                  Submitted
                  <br />
                  Details
                </th>
                <th className={style.col_l}>
                  Database
                  <br />
                  Details
                </th>
                <th className={style.col_s}>Match</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  className={style.col_s}
                  style={{
                    backgroundColor: '#fafaff',
                  }}
                >
                  ID Number
                </td>
                <td className={style.col_l}>{data.queryDetails.idNumber}</td>
                <td className={style.col_l}>
                  {hit.nid === undefined
                    ? hit.idNumber === undefined
                      ? 'N/A'
                      : hit.idNumber
                    : hit.nid}
                </td>
                {hit.aiDecision.idNumber === 'N/A' ? (
                  <td className={style.col_s}>N/A</td>
                ) : hit.aiDecision.idNumber === 'Match' ? (
                  <td
                    className={style.col_s}
                    style={{
                      borderColor: '#00A510',
                      backgroundColor: 'rgba(0,165,16,10%)',
                      fontSize: '1.5rem',
                      color: '#00A510',
                      borderWidth: '2px',
                    }}
                  >
                    <FaRegCheckCircle />
                  </td>
                ) : (
                  <td
                    className={style.col_s}
                    style={{
                      borderColor: '#FF0000',
                      backgroundColor: 'rgba(255,0,0,10%)',
                      fontSize: '1.5rem',
                      color: '#FF0000',
                      borderWidth: '2px',
                    }}
                  >
                    <ImCancelCircle />
                  </td>
                )}
              </tr>
              {!displayIdDetails ? null : (
                <tr>
                  <td
                    className={style.col_s}
                    style={{
                      backgroundColor: '#fafaff',
                    }}
                  >
                    Name
                  </td>
                  <td className={style.col_l}>{data.queryDetails.fullName}</td>
                  <td className={style.col_l}>
                    {hit.name === undefined ? 'N/A' : hit.name}
                  </td>
                  {hit.aiDecision.name === 'N/A' ? (
                    <td className={style.col_s}>N/A</td>
                  ) : hit.aiDecision.name === 'Match' ? (
                    <td
                      className={style.col_s}
                      style={{
                        borderColor: '#00A510',
                        backgroundColor: 'rgba(0,165,16,10%)',
                        fontSize: '1.5rem',
                        color: '#00A510',
                        borderWidth: '2px',
                      }}
                    >
                      <FaRegCheckCircle />
                    </td>
                  ) : (
                    <td
                      className={style.col_s}
                      style={{
                        borderColor: '#FF0000',
                        backgroundColor: 'rgba(255,0,0,10%)',
                        fontSize: '1.5rem',
                        color: '#FF0000',
                        borderWidth: '2px',
                      }}
                    >
                      <ImCancelCircle />
                    </td>
                  )}
                </tr>
              )}
              {!displayIdDetails ? null : (
                <tr>
                  <td
                    className={style.col_s}
                    style={{
                      backgroundColor: '#fafaff',
                    }}
                  >
                    Date of birth
                  </td>
                  <td className={style.col_l}>{data.queryDetails.dob}</td>
                  <td className={style.col_l}>
                    {hit.dob === 'N/A' || hit.dob === undefined
                      ? 'N/A'
                      : selfieOnlyQCWithIdDetails
                      ? hit.dob
                      : moment(hit.dob).format('DD-MM-YYYY')}
                  </td>
                  {hit.aiDecision.dob === 'N/A' ? (
                    <td className={style.col_s}>N/A</td>
                  ) : hit.aiDecision.dob === 'Match' ? (
                    <td
                      className={style.col_s}
                      style={{
                        borderColor: '#00A510',
                        backgroundColor: 'rgba(0,165,16,10%)',
                        fontSize: '1.5rem',
                        color: '#00A510',
                        borderWidth: '2px',
                      }}
                    >
                      <FaRegCheckCircle />
                    </td>
                  ) : (
                    <td
                      className={style.col_s}
                      style={{
                        borderColor: '#FF0000',
                        backgroundColor: 'rgba(255,0,0,10%)',
                        fontSize: '1.5rem',
                        color: '#FF0000',
                        borderWidth: '2px',
                      }}
                    >
                      <ImCancelCircle />
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ClientIdDetailsComparison;
