import React from 'react';

import style from './card.module.css';

type Props = {
  url: string;
  backgroundColor: string;
  color: string;
  heading: string;
  subHeading: string;
  icon: any;
};

const Card = ({
  url,
  backgroundColor,
  color,
  heading,
  subHeading,
  icon,
}: Props) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <div className={style.card}>
        <div
          className={style.icon}
          style={{
            backgroundColor,
            color,
          }}
        >
          {icon}
        </div>
        <div className={style.heading}>{heading}</div>
        <div className={style.sub_heading}>{subHeading}</div>
      </div>
    </a>
  );
};
export default Card;
