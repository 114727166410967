import React, { useEffect, useState } from 'react';
import { Navigation } from '../../../containers/clientReviewPage/interface';
import style from './navigation.module.css';

type Props = {
  data: Navigation;
  changeCurrIndex: (index: number) => void;
  toggleCategory: (togglevalue: string) => void;
  onMismatchTab: boolean;
  onMatchTab: boolean;
  onBlocklistTab: boolean;
};
const NavigationBar = ({
  data,
  toggleCategory,
  changeCurrIndex,
  onMismatchTab,
  onMatchTab,
  onBlocklistTab,
}: Props) => {
  const [matchText, setMatchText] = useState('');
  const [mismatchText, setMismatchText] = useState('');
  const [blocklistText, setBlocklistText] = useState('');

  const toggleMatch = () => {
    if (data.matches.totalIndex > 0) toggleCategory('match');
  };
  const toggleMismatch = () => {
    if (data.mismatches.totalIndex > 0) toggleCategory('mismatch');
  };
  const toggleBlocklist = () => {
    if (data.blocklist.totalIndex > 0) toggleCategory('blocklist');
  };

  const increaseIndex = () => {
    if (onMismatchTab) {
      changeCurrIndex(data.mismatches.currIndex + 1);
    } else if (onBlocklistTab) {
      changeCurrIndex(data.blocklist.currIndex + 1);
    } else {
      changeCurrIndex(data.matches.currIndex + 1);
    }
  };
  const decreaseIndex = () => {
    if (onMismatchTab) {
      changeCurrIndex(data.mismatches.currIndex - 1);
    } else if (onBlocklistTab) {
      changeCurrIndex(data.blocklist.currIndex - 1);
    } else {
      changeCurrIndex(data.matches.currIndex - 1);
    }
  };

  useEffect(() => {
    if (onMismatchTab) {
      setMatchText(`Matches: ${data.matches.totalIndex}`);
      if (data?.buckets?.includes('blocklist')) {
        setBlocklistText(`Blocklist: ${data.blocklist.totalIndex}`);
      }
      if (data.mismatches.totalIndex > 0) {
        setMismatchText(
          `Mismatches: ${data.mismatches.currIndex}/${data.mismatches.totalIndex}`
        );
      } else {
        setMismatchText(`Mismatches: ${data.mismatches.totalIndex}`);
      }
    } else if (onBlocklistTab) {
      if (data.blocklist.totalIndex > 0) {
        setBlocklistText(
          `Blocklist: ${data.blocklist.currIndex}/${data.blocklist.totalIndex}`
        );
      } else {
        setBlocklistText(`Blocklist: ${data.blocklist.totalIndex}`);
      }
      setMatchText(`Matches: ${data.matches.totalIndex}`);
      setMismatchText(`Mismatches: ${data.mismatches.totalIndex}`);
    } else {
      if (data?.buckets?.includes('blocklist')) {
        setBlocklistText(`Blocklist: ${data.blocklist.totalIndex}`);
      }
      if (data.matches.totalIndex > 0) {
        setMatchText(
          `Matches: ${data.matches.currIndex}/${data.matches.totalIndex}`
        );
      } else {
        setMatchText(`Matches: ${data.matches.totalIndex}`);
      }
      setMismatchText(`Mismatches: ${data.mismatches.totalIndex}`);
    }
  }, [data, onMismatchTab, onMatchTab, onBlocklistTab]);

  return (
    <div className={style.navigation}>
      <div
        className={
          onMismatchTab
            ? `${style.navigation_wrapper} ${style.selected}`
            : style.navigation_wrapper
        }
      >
        <button
          type="button"
          disabled={
            data.mismatches.currIndex === 1 ||
            data.mismatches.currIndex === 0 ||
            !onMismatchTab
          }
          onClick={decreaseIndex}
        >
          &lt;
        </button>
        <span onClick={toggleMismatch} style={{ cursor: 'pointer' }}>
          {mismatchText}
        </span>
        <button
          type="button"
          disabled={
            data.mismatches.currIndex === data.mismatches.totalIndex ||
            !onMismatchTab
          }
          onClick={increaseIndex}
        >
          &gt;
        </button>
      </div>
      <div
        className={
          onMatchTab
            ? `${style.navigation_wrapper} ${style.selected}`
            : style.navigation_wrapper
        }
      >
        <button
          type="button"
          disabled={
            data.matches.currIndex === 1 ||
            data.matches.currIndex === 0 ||
            !onMatchTab
          }
          onClick={decreaseIndex}
        >
          &lt;
        </button>
        <span onClick={toggleMatch} style={{ cursor: 'pointer' }}>
          {matchText}
        </span>
        <button
          type="button"
          disabled={
            data.matches.currIndex === data.matches.totalIndex || !onMatchTab
          }
          onClick={increaseIndex}
        >
          &gt;
        </button>
      </div>
      {data?.buckets?.includes('blocklist') ? (
        <div
          className={
            onBlocklistTab
              ? `${style.navigation_wrapper} ${style.selected}`
              : style.navigation_wrapper
          }
        >
          <button
            type="button"
            disabled={
              data.blocklist.currIndex === 1 ||
              data.blocklist.currIndex === 0 ||
              !onBlocklistTab
            }
            onClick={decreaseIndex}
          >
            &lt;
          </button>
          <span onClick={toggleBlocklist} style={{ cursor: 'pointer' }}>
            {blocklistText}
          </span>
          <button
            type="button"
            disabled={
              data.blocklist.currIndex === data.blocklist.totalIndex ||
              !onBlocklistTab
            }
            onClick={increaseIndex}
          >
            &gt;
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default NavigationBar;
