// This project is setup using redux-toolkit which includes immer by default.
// Hence state is mutable.

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import nullFaceAuthData from './initialState';
import { FaceAuthReviewLog, FaceAuthReviewPageState } from './interface';

const initialState: FaceAuthReviewPageState = {
  faceAuthReviewPageData: nullFaceAuthData,
};

export const faceAuthReviewSlice = createSlice({
  name: 'faceauthreview',
  initialState,
  reducers: {
    setFaceAuthReviewPageData: (
      state,
      action: PayloadAction<FaceAuthReviewLog>
    ) => {
      state.faceAuthReviewPageData = action.payload;
    },
  },
});

export const { setFaceAuthReviewPageData } = faceAuthReviewSlice.actions;

export const selectFaceAuthReviewPageData = (
  state: RootState
): FaceAuthReviewLog => state.faceauthreview.faceAuthReviewPageData;

export default faceAuthReviewSlice.reducer;
