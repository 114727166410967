/* eslint-disable no-nested-ternary */
import React from 'react';

import NA from '../../../assets/sample/na.jpg';
import style from './clientIdFaceComparison.module.css';
import Label from '../label';
import { ClientReviewHit } from '../../../containers/clientReviewPage/interface';
import { IdFaceComparisonI } from '../../../containers/dedupeReviewPage/interface';

type Props = {
  hit: ClientReviewHit;
  data: IdFaceComparisonI;
};

const ClientIdFaceComparison = ({ hit, data }: Props) => {
  return (
    <div className={style.container_lg}>
      <div className={style.container_wrapper}>
        <div className={style.container_heading}>ID Face Comparison</div>
        <div className={style.container_content}>
          <div className={style.query}>
            <span>Submitted ID Face</span>
            <div className={style.query_wrapper}>
              <img
                className={style.img_size}
                src={
                  data.capturedIdFace === 'NA' || data.capturedIdFace === 'N/A'
                    ? NA
                    : data.capturedIdFace
                }
                alt=""
              />
            </div>
          </div>
          <div className={style.db}>
            <span>Database ID Face</span>
            <div className={style.db_wrapper}>
              <img
                className={style.img_size}
                src={
                  hit.idImages.fraasCrop === 'NA' ||
                  hit.idImages.fraasCrop === 'N/A'
                    ? NA
                    : hit.idImages.fraasCrop
                }
                alt=""
              />
            </div>
          </div>
          <div className={style.result}>
            <Label
              itemKey="ID Face Match"
              value={
                hit.aiDecision.idFace === 'N/A'
                  ? hit.aiDecision.idFace
                  : hit.aiDecision.idFace === 'Match'
                  ? 'tick'
                  : 'cross'
              }
              fontSize={hit.aiDecision.idFace === 'N/A' ? '1rem' : '1.5rem'}
              padding="0.7rem 1rem"
              borderColor={
                hit.aiDecision.idFace === 'N/A'
                  ? '#ededf4'
                  : hit.aiDecision.idFace === 'Match'
                  ? '#00A510'
                  : '#FF0000'
              }
              valueColor={
                hit.aiDecision.idFace === 'N/A'
                  ? '#000000'
                  : hit.aiDecision.idFace === 'Match'
                  ? '#00A510'
                  : '#FF0000'
              }
              color={
                hit.aiDecision.idFace === 'N/A'
                  ? '#000000'
                  : hit.aiDecision.idFace === 'Match'
                  ? '#00A510'
                  : '#FF0000'
              }
              fontWeight={800}
              backgroundColor={
                hit.aiDecision.idFace === 'N/A'
                  ? '#fafaff'
                  : hit.aiDecision.idFace === 'Match'
                  ? 'rgba(0,156,16,10%)'
                  : 'rgba(255,0,0,10%)'
              }
            />
            <Label
              itemKey="Match Score"
              value={
                typeof hit.matchScore.idFaceCrop === 'number'
                  ? hit.matchScore.idFaceCrop.toFixed(2)
                  : 'N/A'
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientIdFaceComparison;
