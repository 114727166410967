import React from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from '../../containers/loginPage/loginSlice';
import SearchBar from '../searchBar/index';
import style from './homePage.module.css';

const HomePage = () => {
  const dispatch = useDispatch();

  const logOutHandler = () => {
    dispatch(signOut());
  };

  const render = (
    <div className={`${style.Homepage}`}>
      <SearchBar logOut={logOutHandler} />
      <h1>Orion QC Portal</h1>
      <h5>Enter RequestId to continue</h5>
    </div>
  );
  return render;
};

export default HomePage;
