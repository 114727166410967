import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateFreshChatInitialised,
  updateUserInitialized,
} from './app/utils/FCSlice';

declare global {
  interface Window {
    fcWidget: any;
    attachEvent: any;
  }
}

const FCInitScript = () => {
  const dispatch = useDispatch();
  const firebaseProfile = useSelector((state: any) => state.firebase.profile);
  const firebaseAuth = useSelector((state: any) => state.firebase.auth);

  useEffect(() => {
    dispatch(
      updateUserInitialized({
        email: firebaseAuth.email,
        clientId: firebaseProfile.appId,
        name: firebaseProfile.userName,
      })
    );
  }, [dispatch, firebaseAuth, firebaseProfile]);

  useEffect(() => {
    const initFreshChat = () => {
      if (window.fcWidget) {
        window.fcWidget.init({
          token: process.env.REACT_APP_FRESHCHAT_TOKEN,
          host: 'https://wchat.in.freshchat.com',
          siteId: process.env.REACT_APP_FRESHCHAT_SITE_ID || 'nebula_dashboard',
        });
      }
    };

    const initialize = (i: any, t: any) => {
      let e;
      if (i.getElementById(t)) {
        initFreshChat();
      } else {
        e = i.createElement('script');
        e.id = t;
        e.async = !0;
        e.src = 'https://wchat.freshchat.com/js/widget.js';
        e.onload = () => {
          initFreshChat();
          dispatch(updateFreshChatInitialised());
        };
        i.head.appendChild(e);
      }
    };

    const initiateCall = () => {
      initialize(document, 'freshchat-js-sdk');
    };

    if (window.fcWidget) {
      dispatch(updateFreshChatInitialised());
    } else if (window.addEventListener) {
      if (document.readyState === 'complete') {
        initiateCall();
      } else {
        window.addEventListener('load', initiateCall, !1);
      }
    } else {
      window.attachEvent('load', initiateCall, !1);
    }
  }, [dispatch]);
};

export default FCInitScript;
