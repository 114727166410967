import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {
  firebaseReducer,
  getFirebase,
  actionTypes as rrfActionTypes,
} from 'react-redux-firebase';
import { firestoreReducer, constants as rfConstants } from 'redux-firestore';
import reviewReducer from './containers/reviewPage/reviewSlice';
import loginReducer from './containers/loginPage/loginSlice';
import clientReviewReducer from './containers/clientReviewPage/clientReviewPageSlice';
import dedupeReviewReducer from './containers/dedupeReviewPage/dedupeReviewPageSlice';
import faceAuthReviewReducer from './containers/faceAuthReviewPage/faceAuthReviewPageSlice';
import alertReducer from './components/clientComponents/alert/alertSlice';
import documentationpageReducer from './containers/documentationPage/documentationPageSlice';

export const store = configureStore({
  reducer: {
    clientreview: clientReviewReducer,
    review: reviewReducer,
    login: loginReducer,
    dedupereview: dedupeReviewReducer,
    faceauthreview: faceAuthReviewReducer,
    documentationpage: documentationpageReducer,
    firebase: firebaseReducer,
    alert: alertReducer,
    firestore: firestoreReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          // just ignore every redux-firebase and react-redux-firebase action type
          ...Object.keys(rfConstants.actionTypes).map(
            (type) => `${rfConstants.actionsPrefix}/${type}`
          ),
          ...Object.keys(rrfActionTypes).map(
            (type) => `@@reactReduxFirebase/${type}`
          ),
        ],
        ignoredPaths: ['firebase', 'firestore'],
      },
      thunk: {
        extraArgument: getFirebase,
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  typeof getFirebase,
  Action<string>
>;
