/* eslint-disable no-nested-ternary */
import React from 'react';
import NA from '../../../assets/sample/na.jpg';
import style from './selfieComparison.module.css';

import Label from '../label';
import { ClientReviewHit } from '../../../containers/clientReviewPage/interface';
import { SelfieComparisonI } from '../../../containers/dedupeReviewPage/interface';

type Props = {
  hit: ClientReviewHit;
  data: SelfieComparisonI;
  faceSearchQC: boolean;
  customClientChangeCathay: boolean;
  enrolStatus: boolean | string;
  enrolReason: string;
};

const ClientSelfieComparison = ({
  hit,
  data,
  faceSearchQC,
  customClientChangeCathay,
  enrolStatus,
  enrolReason,
}: Props) => {
  return (
    <div className={style.container_lg}>
      <div className={style.container_wrapper}>
        {customClientChangeCathay ? null : (
          <div className={style.container_heading}>Selfie Comparison</div>
        )}
        <div className={style.container_content}>
          <div className={style.query}>
            <span>Submitted Selfie</span>
            <div className={style.query_wrapper}>
              <img
                className={style.img_size}
                src={
                  data.capturedSelfie === 'NA' || data.capturedSelfie === 'N/A'
                    ? NA
                    : data.capturedSelfie
                }
                alt=""
              />
            </div>
          </div>
          {customClientChangeCathay ? null : (
            <div className={style.db}>
              <span>Database Selfie</span>
              <div className={style.db_wrapper}>
                <img
                  className={style.img_size}
                  src={
                    hit.selfieImages.original === 'NA' ||
                    hit.selfieImages.original === 'N/A'
                      ? NA
                      : hit.selfieImages.original
                  }
                  alt=""
                />
              </div>
            </div>
          )}
          {customClientChangeCathay ? (
            <div className={style.result}>
              <Label
                itemKey="Enrollment status"
                value={
                  enrolStatus === 'N/A'
                    ? enrolStatus
                    : enrolStatus
                    ? 'tick'
                    : 'cross'
                }
                fontSize={enrolStatus === 'N/A' ? '1rem' : '1.5rem'}
                padding="0.7rem 1rem"
                borderColor={
                  enrolStatus === 'N/A'
                    ? '#ededf4'
                    : enrolStatus
                    ? '#00A510'
                    : '#FF0000'
                }
                valueColor={
                  enrolStatus === 'N/A'
                    ? '#000000'
                    : enrolStatus
                    ? '#00A510'
                    : '#FF0000'
                }
                color={
                  enrolStatus === 'N/A'
                    ? '#000000'
                    : enrolStatus
                    ? '#00A510'
                    : '#FF0000'
                }
                fontWeight={800}
                backgroundColor={
                  enrolStatus === 'N/A'
                    ? '#fafaff'
                    : enrolStatus
                    ? 'rgba(0,156,16,10%)'
                    : 'rgba(255,0,0,10%)'
                }
              />
              {!enrolStatus ? (
                <Label itemKey="Reason" value={enrolReason} />
              ) : null}
            </div>
          ) : (
            <div className={style.result}>
              {faceSearchQC ? (
                <Label
                  itemKey="Database ApplicationID"
                  value={
                    hit.applicationId === 'N/A' || !hit.applicationId
                      ? 'N/A'
                      : hit.applicationId
                  }
                />
              ) : (
                <Label
                  itemKey="Selfie Match"
                  value={
                    hit.aiDecision.selfie === 'N/A'
                      ? hit.aiDecision.selfie
                      : hit.aiDecision.selfie === 'Match'
                      ? 'tick'
                      : 'cross'
                  }
                  fontSize={hit.aiDecision.selfie === 'N/A' ? '1rem' : '1.5rem'}
                  padding="0.7rem 1rem"
                  borderColor={
                    hit.aiDecision.selfie === 'N/A'
                      ? '#ededf4'
                      : hit.aiDecision.selfie === 'Match'
                      ? '#00A510'
                      : '#FF0000'
                  }
                  valueColor={
                    hit.aiDecision.selfie === 'N/A'
                      ? '#000000'
                      : hit.aiDecision.selfie === 'Match'
                      ? '#00A510'
                      : '#FF0000'
                  }
                  color={
                    hit.aiDecision.selfie === 'N/A'
                      ? '#000000'
                      : hit.aiDecision.selfie === 'Match'
                      ? '#00A510'
                      : '#FF0000'
                  }
                  fontWeight={800}
                  backgroundColor={
                    hit.aiDecision.selfie === 'N/A'
                      ? '#fafaff'
                      : hit.aiDecision.selfie === 'Match'
                      ? 'rgba(0,156,16,10%)'
                      : 'rgba(255,0,0,10%)'
                  }
                />
              )}
              <Label
                itemKey="Match Score"
                value={
                  typeof hit.matchScore.selfie === 'number'
                    ? hit.matchScore.selfie.toFixed(2)
                    : 'N/A'
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientSelfieComparison;
