/* eslint-disable no-nested-ternary */
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { addAlert } from '../alert/alertSlice';
import BigLoader from '../bigLoader';
import style from './listPage.module.css';

type Props = {
  idListPage: boolean;
};

interface IdListI {
  clientId: string;
  transactionId: string;
  requestId: string;
  timestamp: string;
  endpoint: string;
}

const ListPage = ({ idListPage }: Props) => {
  const [items, setItems] = useState<IdListI[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoader] = useState(false);

  const firebaseAuth = useSelector((state: any) => state.firebase.auth);
  const firebase = useFirebase();

  const getEndpointMap = (endpoint: string) => {
    if (endpoint === '/faceauth') {
      return 'Verify';
    }
    if (endpoint === '/v2/search/face') {
      return 'Register';
    }
    return endpoint;
  };

  useEffect(() => {
    const getToken = async (): Promise<string> => {
      if (!firebaseAuth.uid) return '';
      try {
        const token = await firebase.auth().currentUser?.getIdToken(true);
        return token || '';
      } catch (err) {
        return '';
      }
    };

    const fetchData = async () => {
      setLoader(true);
      const token = await getToken();
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/transactionIds?page=${
            pageNumber - 1
          }&size=20`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 200) {
          const resultItems = res.data.map((dataItem: IdListI) => {
            return {
              ...dataItem,
              endpoint: getEndpointMap(dataItem.endpoint),
            };
          });
          setItems(resultItems);
        }
      } catch (err: any) {
        addAlert({
          message: err?.response?.data || 'Something went wrong',
          messageType: 'FAILURE',
        });
      }
      setLoader(false);
    };
    if (idListPage) {
      fetchData();
    }
  }, [firebaseAuth, firebase, pageNumber, idListPage]);

  const fetchNextData = async () => {
    setPageNumber(pageNumber + 1);
  };

  const fetchPreviousData = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const idListPageRender = (
    <div className={style.list_page}>
      <table className={style.list_page_table}>
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Request ID</th>
            <th>Unique ID</th>
            <th>User action</th>
          </tr>
        </thead>
        {items.length === 0 ? (
          <tbody>
            <tr>
              <td />
              <td>Looks like we reached the finish line!</td>
              <td />
              <td />
            </tr>
          </tbody>
        ) : (
          <tbody>
            {items.map((item) => (
              <tr key={`${item.requestId}${Math.random()}`}>
                <td>
                  {item.timestamp
                    ? item.timestamp !== 'NA'
                      ? new Date(item.timestamp).toLocaleString()
                      : 'NA'
                    : 'NA'}
                </td>
                <td>
                  <a
                    href={`/reviewportal/${item.requestId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.requestId}
                  </a>
                </td>
                <td>{item.transactionId}</td>
                <td>{item.endpoint}</td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      <div className={style.list_page_nav}>
        <span onClick={fetchPreviousData}>Previous</span>
        <span> {pageNumber} </span>
        <span onClick={fetchNextData}>Next</span>
      </div>
    </div>
  );

  const idListPageWithLoader = loading ? (
    <div className={style.loader_wrapper}>
      <BigLoader />
    </div>
  ) : (
    idListPageRender
  );

  return idListPage ? (
    idListPageWithLoader
  ) : (
    <div className={style.list_page} />
  );
};
export default ListPage;
