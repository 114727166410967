import {
  ClientFaceAuthLog,
  ClientQueryLog,
} from '../clientReviewPage/interface';
import { FaceAuthReviewLog } from '../faceAuthReviewPage/interface';
import { ReviewPageData } from './interface';

export const getDedupeReviewPageData = (
  queryLog: ClientQueryLog
): ReviewPageData => {
  const totalMatches = queryLog.count?.total?.matches;
  const totalMismatches = queryLog.count?.total?.mismatches;
  const inputImages = queryLog.images;
  const inputDetails = queryLog.input;
  const clientId = queryLog.clientId || 'NA';
  const selfieOnlyQCFlag = ['/v2/search', '/v2/search/face'].includes(
    queryLog.endpoint
  );
  const selfieOnlyQCWithIdDetails = ['/v2/search'].includes(queryLog.endpoint);
  const faceSearchQCFlag = ['/v2/search/face'].includes(queryLog.endpoint);

  const { check } = queryLog;
  const blurValue = check?.selfie?.quality?.blur?.overall;
  const verifyCheckValue = check?.verify?.value;

  const dedupeReviewPageData = {
    tagBar: {
      applicationId: selfieOnlyQCFlag
        ? inputDetails.query.applicationId || 'N/A'
        : inputDetails.user.applicationId || 'N/A',
      qualityCheck: selfieOnlyQCFlag ? blurValue : verifyCheckValue,
      selfieIdMatch: check?.verifyPair?.match || 'N/A',
      aggregatorUrl: queryLog.aggregatorUrl,
    },
    results: {
      selfieComparison: {
        capturedSelfie: inputImages.selfieImages.original,
        databaseSelfie: 'N/A',
        selfieMatch: 'N/A',
        matchScore: 'N/A',
      },
      idFaceComparison: {
        capturedIdFace: inputImages?.idImages?.fraasCrop,
        databaseIdFace: 'N/A',
        IdFaceMatch: 'N/A',
        matchScore: 'N/A',
      },
      idDetailsComparison: {
        queryDetails: {
          idNumber: inputDetails.query.idNumber || 'N/A',
          fullName: inputDetails.query.name || 'N/A',
          dob: inputDetails.query.dob || 'N/A',
        },
        dbDetails: {
          idNumber: 'N/A',
          fullName: 'N/A',
          dob: 'N/A',
        },
        matchDetails: {
          idNumber: 'N/A',
          fullName: 'N/A',
          dob: totalMatches || totalMismatches ? 'Exist' : 'N/A',
        },
      },
      verdict: {
        reason: 'N/A',
      },
      enrolStatus: queryLog.check?.enrol?.value,
      enrolReason: queryLog.check?.enrol?.reason || 'NA',
    },
    suspicious: queryLog.hits.mismatches.length > 0,
    selfieOnlyQC: selfieOnlyQCFlag,
    selfieOnlyQCWithIdDetails,
    faceSearchQC: faceSearchQCFlag,
    clientId,
    timestamp:
      queryLog.timestamp && queryLog.timestamp !== 'NA'
        ? new Date(queryLog.timestamp).toLocaleString()
        : 'NA',
    customClientChangeCathay: clientId === 'ac6822',
    displayHitDob: ["839516", "ce3e8e"].includes(clientId),
  };
  return dedupeReviewPageData;
};

export const getFaceAuthReviewPageData = (
  queryLog: ClientFaceAuthLog
): FaceAuthReviewLog => {
  const faceAuthReviewPageData = {
    requestId: queryLog.requestId,
    clientId: queryLog.clientId,
    endpoint: queryLog.endpoint,
    aggregatorUrl: queryLog.aggregatorUrl,
    transactionId: queryLog.transactionId,
    timestamp: new Date(queryLog.timestamp).toLocaleString(),
    images: {
      selfieImages: {
        original: queryLog.images.selfieImages.original,
        fraasCrop: queryLog.images.selfieImages.fraasCrop,
      },
    },
    check: {
      selfie: {
        quality: {
          faceQuality: queryLog.check.selfie.quality.faceQuality,
          liveness: queryLog.check.selfie.quality.liveness,
        },
      },
    },
    hit: {
      requestId: queryLog.hit.requestId,
      transactionId: queryLog.hit.transactionId,
      clientId: queryLog.hit.clientId,
      distance: queryLog.hit.distance,
      match: queryLog.hit.match,
      matchScore: queryLog.hit.matchScore,
      images: {
        selfieImages: {
          original: queryLog.hit.images.selfieImages.original,
          fraasCrop: queryLog.hit.images.selfieImages.fraasCrop,
        },
      },
    },
    error: queryLog.error,
  };

  return faceAuthReviewPageData;
};
