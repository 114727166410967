/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

declare global {
  interface Window {
    fcWidget: any;
  }
}

export const initializationSlice = createSlice({
  name: 'freshchat',
  initialState: {
    freshchatInitialized: false,
    userInitialized: false,
    email: '',
    name: '',
    clientId: '',
  },
  reducers: {
    updateFreshChatInitialised: (state) => {
      state.freshchatInitialized = true;
      if (state.userInitialized) {
        window.fcWidget.user.setProperties({
          email: state.email,
          firstName: state.name,
        });
        window.fcWidget.user.setMeta({
          clientId: state.clientId,
        });
      }
    },
    updateUserInitialized: (state, action) => {
      const { email, name, clientId } = action.payload;
      state.userInitialized = true;
      state.email = email;
      state.name = name;
      state.clientId = clientId;
      if (state.freshchatInitialized) {
        window.fcWidget.user.setProperties({
          email,
          firstName: name,
        });
        window.fcWidget.user.setMeta({
          clientId,
        });
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateFreshChatInitialised, updateUserInitialized } =
  initializationSlice.actions;
export default initializationSlice.reducer;
